import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import LandingPage from '../src/components/LandingPageM2M'
import { detectMobile } from '../src/utils/detectMobile'

const Pages = (props) => {
  const { isMobileDevice } = props
  return <LandingPage isMobileDevice={isMobileDevice} isHeaderLandingPage/>
}

export async function getServerSideProps({ params, req }) {
  const { locale } = params
  const userAgent = req.headers['user-agent']
  const isMobileDevice = detectMobile(userAgent)
  const translations = await serverSideTranslations(locale, [
    'common',
    'mToM',
    'landingPage',
    'checkProduct',
    'aboutEsim'
  ])

  return {
    props: {
      isMobileDevice,
      ...translations,
    },
  }
}
export default Pages
